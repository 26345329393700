import * as fns from 'date-fns';

import { useApp } from 'components/ContextApp';

import { Translation, getLocaleObject, mapLanguage } from '../components/Language';

import { ClientLanguage } from './language';

interface ParseDateParams {
  withYear?: boolean;
  withTime?: boolean;
  onlyTime?: boolean;
  format?: string;
  language?: ClientLanguage;
}
const defaultParseParams: ParseDateParams = {
  withTime: true,
  withYear: false,
  onlyTime: false,
};

export function useDateFormatter(
  date: unknown,
  options: ParseDateParams = defaultParseParams,
): string {
  const { language } = useApp();
  if (!language) {
    throw new Error('useDateFormatter tried to get language, but it is null');
  }

  options.language = language;

  if (!date) {
    return '';
  }

  return parseDate(date, options);
}

/** @deprecated */
export function parseDate(
  dateStr: unknown,
  options: ParseDateParams = defaultParseParams,
): string {
  const params = { ...defaultParseParams, ...options };

  const date = new Date(dateStr as string);

  const locale = getLocaleObject(options.language);
  const text = locale.controls.date;

  const num = fns.getMonth(date) as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

  const year = fns.getYear(date);
  const day = fns.getDate(date).toString();
  const month = text.months[num];
  const hours = fns.getHours(date).toString().padStart(2, '0');
  const mins = fns.getMinutes(date).toString().padStart(2, '0');

  const timePostfix = params.withTime ? `, ${hours}:${mins}` : '';
  const yearPostfix = params.withYear ? ` ${year}` : '';
  const postfix = timePostfix + yearPostfix;

  if (params.onlyTime) {
    return `${hours}:${mins}`;
  }

  if (fns.isToday(date)) {
    return text.today + postfix;
  }
  if (fns.isTomorrow(date)) {
    return text.tomorrow + postfix;
  }
  if (fns.isYesterday(date)) {
    return text.yesterday + postfix;
  }

  if (params.format) {
    return fns.format(date, params.format) + timePostfix;
  }

  if (!month) {
    return '';
  }

  return month.dat(day) + postfix;
}

export function getDateEssence(date: Date): string {
  return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
}

export function dateToShortText(
  date: Date,
  locale: ClientLanguage = ClientLanguage.English,
): string {
  const dayNum = date.getDate();
  const month = months[date.getMonth()][mapLanguage(locale)];

  return `${dayNum} ${month}`;
}

const months: Array<Translation> = [
  { russian: 'янв', english: 'jan', arabic: 'يناير' },
  { russian: 'фев', english: 'feb', arabic: 'فبراير' },
  { russian: 'мар', english: 'mar', arabic: 'مارس' },
  { russian: 'апр', english: 'apr', arabic: 'أبريل' },
  { russian: 'май', english: 'may', arabic: 'مايو' },
  { russian: 'июн', english: 'jun', arabic: 'يونيو' },
  { russian: 'июл', english: 'jul', arabic: 'يوليو' },
  { russian: 'авг', english: 'aug', arabic: 'أغسطس' },
  { russian: 'сен', english: 'sep', arabic: 'سبتمبر' },
  { russian: 'окт', english: 'oct', arabic: 'أكتوبر' },
  { russian: 'ноя', english: 'nov', arabic: 'نوفمبر' },
  { russian: 'дек', english: 'dec', arabic: 'ديسمبر' },
];
