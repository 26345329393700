import { api } from '../api';

export function isWow(
  gameId: api.Maybe<api.GameId>,
): gameId is api.GameId.WorldOfWarcraft {
  if (!gameId) {
    return false;
  }

  return [
    api.GameId.WorldOfWarcraft,
    api.GameId.WorldOfWarcraftBurningCrusade,
  ].includes(gameId);
}

export function castWowRoleToSlotRole(
  role: api.Maybe<api.WowRole>,
): api.Maybe<api.SlotRole> {
  switch (role) {
    case api.WowRole.Damage:
      return api.SlotRole.WowDamage;
    case api.WowRole.Healer:
      return api.SlotRole.WowHeal;
    case api.WowRole.Tank:
      return api.SlotRole.WowTank;
    case undefined:
      return undefined;
  }
}

export function isBlizzard(gameId: api.Maybe<api.GameId>): boolean {
  if (!gameId) {
    return false;
  }

  return (
    isWow(gameId) ||
    [
      api.GameId.Hearthstone,
      api.GameId.Overwatch,
      api.GameId.WorldOfWarcraftBurningCrusade,
    ].includes(gameId)
  );
}

export function parseGameValue(value?: string): string {
  if (!value) {
    return '';
  }

  return value
    .split('-')
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(' ');
}

export function getUpdatedAt(updatedAt: Date): number {
  if (!updatedAt) {
    return 1;
  }

  return new Date(updatedAt).getTime();
}

export function getArmoryLinkByLfg(game: api.Wow, lfg: api.Lfg): string {
  const region = lfg.wow?.region || api.WowRegion.Europe;
  const battlenetRegion = region === api.WowRegion.Europe ? 'eu' : 'us';

  // https://worldofwarcraft.com/character/eu/soulflayer/Лувисфолл
  return `https://worldofwarcraft.com/character/${battlenetRegion}/${game.realm}/${game.alias}`;
}
