import { useApp } from 'components/ContextApp';
import { getTranslation, useText, useTranslations } from 'components/Language';
import { ClientLanguage } from 'utils/language';

export { useText, getTranslation, useTranslations };

export type LocaleMap<T = string> = Record<ClientLanguage, T>;

/**
 * useLocaleMap is used for STATIC content routing, such as documentation links
 * (one link for english-speaking, another for russian-speaking users)
 */
export function useLocaleMap<T>(values: LocaleMap<T>): T {
  const { language } = useApp();

  if (!language) {
    return values['en'];
  }

  return values[language];
}
