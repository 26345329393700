/* eslint-disable @typescript-eslint/no-explicit-any */

import type { DocumentNode } from 'graphql';

export function pickRandom<T>(items: T[]): T {
  return items[Math.floor(Math.random() * items.length)];
}

export interface ApolloMockClient<T, Vars> {
  mocks: ApolloMock<T, Vars>[];
}

export interface ApolloMock<T = any, Vars = JSON> {
  request: {
    query: DocumentNode;
    operationName?: string;
    variables?: Vars;
    context?: JSON;
  };
  result: { data: (() => T) | T };
  error?: Error;
}

export function mockClient<T, Vars>(
  ...mocks: ApolloMock<T, Vars>[]
): ApolloMockClient<T, Vars> {
  return { mocks };
}
