import React from 'react';

import cx from 'classnames';

import { api } from 'api';

import { ID_CLICKABLE } from 'utils/main';

import css from './List.module.css';

export interface ListProps<T> {
  items: api.Maybe<T[]>;
  renderItem: (item: T) => React.ReactNode;
  itemKey?: (item: T) => React.Key;
  onSelectItem?: (item: T) => void;
  className?: string;
}

export function List<T>({
  items = [],
  renderItem,
  onSelectItem = () => undefined,
  className,
  itemKey,
}: ListProps<T>): React.ReactElement {
  return (
    <div className={cx(css.listContainer, className)}>
      {items?.map((item, key) => (
        <div
          key={itemKey ? itemKey(item) : key}
          className={css.listItem}
          id={ID_CLICKABLE}
          onClick={() => onSelectItem(item)}
        >
          {renderItem(item)}
        </div>
      ))}
    </div>
  );
}
