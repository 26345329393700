import { api } from '../api';

import { getCurrentLocationGame } from './links';

import { cookie } from './main';

export const COOKIE_REFERRAL_CODE_KEY = 'referral_code';
export const COOKIE_TASTYDROP_USER_ID = 'tastydrop_user_id';

export function getReferralCodeFromCookie(): string | undefined {
  return cookie.get(COOKIE_REFERRAL_CODE_KEY);
}

export function getTastydropUserIdFromCookie(): string | undefined {
  return cookie.get(COOKIE_TASTYDROP_USER_ID);
}

// for popup oauth authorization
const createAuthStorage = () => {
  const discordCodePath = 'auth/discord/code';
  const twitchCodePath = 'auth/twitch/code';
  const battlenetCodePath = 'auth/battlenet/code';
  const steamCodePath = 'auth/steam/code';
  const riotCodePath = 'auth/riot/code';
  const googleCodePath = 'auth/google/code';
  const facebookCodePath = 'auth/facebook/code';

  interface AuthStorageService {
    setCode(code: string): void;
    getCode(): string | undefined;
    resetSession(): void;
  }

  const createAuthService = (codePath: string): AuthStorageService => {
    return {
      getCode: () => cookie.get(codePath),
      setCode: (code: string): void => {
        cookie.set(codePath, code, 1);
      },
      resetSession: () => {
        cookie.delete(codePath);
      },
    };
  };

  return {
    discord: createAuthService(discordCodePath),
    twitch: createAuthService(twitchCodePath),
    battlenet: createAuthService(battlenetCodePath),
    steam: createAuthService(steamCodePath),
    riot: createAuthService(riotCodePath),
    google: createAuthService(googleCodePath),
    facebook: createAuthService(facebookCodePath),
  };
};
export const authStorage = createAuthStorage();

export function waitForWindowClose(refWindow: Window): Promise<void> {
  return new Promise(resolve => {
    const timer = setInterval(() => {
      if (refWindow.closed) {
        clearInterval(timer);
        resolve();
      }
    }, 100);
  });
}

export function openOAuthPopupWindow(url: string): Window {
  if (!window || !window.top) {
    throw new Error('Browser not initialized');
  }

  const newWindowWidth = 520;
  const newWindowHeight = 750;
  const top = window.top.outerHeight / 2 + window.top.screenY - newWindowHeight / 2;
  const left = window.top.outerWidth / 2 + window.top.screenX - newWindowWidth / 2;
  const authWindowRef = window.open(
    url,
    '_blank',
    `location=yes,height=${newWindowHeight},width=${newWindowWidth},scrollbars=yes,status=yes,left=${left},top=${top}`,
  );
  if (authWindowRef === null) {
    throw new Error('Window not opened');
  }

  return authWindowRef;
}

export function parseAuthReferralData(data: api.OAuthData) {
  const tastydropUserId = getTastydropUserIdFromCookie();
  if (tastydropUserId && tastydropUserId != '') {
    data.id = tastydropUserId;
    data.source = 'tastydrop';

    return;
  }

  const locationGame = getCurrentLocationGame(location.pathname);
  if (locationGame === api.GameId.LeagueOfLegends) {
    data.source = 'lol';
  } else if (
    locationGame === api.GameId.Csgo ||
    location.pathname === '/skinclub' ||
    location.pathname === '/skinclub-giveaway'
  ) {
    data.source = 'csgo';
  }

  data.referralCode = getReferralCodeFromCookie();

  return;
}

export const getDiscordAuthLink = (
  clientId = '776514408578940969',
  redirectUri = `${window.location.origin}/auth/discord`,
  scope = 'email%20identify%20guilds.join',
) => {
  const authLink = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`;

  return authLink;
};

export async function getDiscordAuthData(
  scope = 'email%20identify%20guilds.join',
): Promise<api.OAuthData> {
  const redirectUri = `${window.location.origin}/auth/discord`;
  const clientId = `776514408578940969`;
  const authLink = getDiscordAuthLink(clientId, redirectUri, scope);

  const authWindowRef = openOAuthPopupWindow(authLink);
  authWindowRef.focus();

  await waitForWindowClose(authWindowRef);

  const code = authStorage.discord.getCode();
  if (!code) {
    throw new Error('discord auth code is missing');
  }

  const data: api.OAuthData = {
    code,
    scope,
    redirectUri,
  };
  parseAuthReferralData(data);

  return data;
}

export async function getRiotAuthData(): Promise<api.OAuthData> {
  const redirectUri = `${window.location.origin}/auth/riot`;
  const clientId = 'lfgroup';
  const authLink = `https://auth.riotgames.com/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=cpid%20openid%20offline_access&prompt=login`;

  const referralCode = getReferralCodeFromCookie();

  const authWindowRef = openOAuthPopupWindow(authLink);
  authWindowRef.focus();
  await waitForWindowClose(authWindowRef);
  const code = authStorage.riot.getCode();
  if (!code) {
    throw new Error('riot auth code is missing');
  }

  return { code, redirectUri, referralCode };
}

export async function getTwitchAuthData(): Promise<api.OAuthData> {
  const redirectUri = `${window.location.origin}/auth/twitch`;
  const twitchClientId = 'xev1bfb49czgbrrtms1sqt3agh7wya';
  const authLink = `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${twitchClientId}&redirect_uri=${redirectUri}&scope=user_read user:read:email`;

  const authWindowRef = openOAuthPopupWindow(authLink);
  authWindowRef.focus();
  await waitForWindowClose(authWindowRef);
  const code = authStorage.twitch.getCode();
  if (!code) {
    throw new Error('twitch auth code is missing');
  }

  const data: api.OAuthData = { code, redirectUri };
  parseAuthReferralData(data);

  return data;
}

// https://discord.com/oauth2/authorize?client_id=776514408578940969&redirect_uri=https://lf.group&response_type=code&scope=email%20identify%20guilds.join
export async function getBattlenetAuthData(
  region: api.BattlenetRegion,
): Promise<api.OAuthData> {
  const redirectUri = `${window.location.origin}/auth/battlenet`;
  const clientId = '491b2df022d44d5e89ee052d3f5d8151';

  const referralCode = getReferralCodeFromCookie();

  const getDomain = () => {
    switch (region) {
      case api.BattlenetRegion.Europe:
        return 'https://eu.battle.net';
      case api.BattlenetRegion.Us:
        return 'https://us.battle.net';
      default:
        throw new Error('undefined region');
    }
  };
  const authLink = `${getDomain()}/oauth/authorize?access_type=online&client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=wow.profile`;

  const authWindowRef = openOAuthPopupWindow(authLink);
  authWindowRef.focus();

  await waitForWindowClose(authWindowRef);
  const code = authStorage.battlenet.getCode();
  if (!code) {
    throw new Error('battlenet code is missing');
  }

  const data: api.OAuthData = { code, redirectUri, region, referralCode };
  parseAuthReferralData(data);

  return data;
}

export async function getSteamAuthData(): Promise<api.OAuthData> {
  const origin = window.location.origin;
  const redirectUri = `${origin}/auth/steam`;
  const openid = {
    claimedId: encodeURIComponent(
      'http://specs.openid.net/auth/2.0/identifier_select',
    ),
    identity: encodeURIComponent(
      'http://specs.openid.net/auth/2.0/identifier_select',
    ),
    mode: encodeURIComponent('checkid_setup'),
    ns: encodeURIComponent('http://specs.openid.net/auth/2.0'),
    realm: encodeURIComponent(`${origin}/`),
    returnTo: encodeURIComponent(redirectUri),
  };

  const referralCode = getReferralCodeFromCookie();

  const authLink = `https://steamcommunity.com/openid/login?openid.claimed_id=${openid.claimedId}&openid.identity=${openid.identity}&openid.mode=${openid.mode}&openid.ns=${openid.ns}&openid.realm=${openid.realm}&openid.return_to=${openid.returnTo}`;
  const authWindowRef = openOAuthPopupWindow(authLink);
  authWindowRef.focus();
  await waitForWindowClose(authWindowRef);
  const code = authStorage.steam.getCode();
  if (!code) {
    throw new Error('steam auth code is missing');
  }

  const data: api.OAuthData = { url: code, referralCode };
  parseAuthReferralData(data);

  return data;
}

export async function getGoogleAuthData(): Promise<api.OAuthData> {
  const redirectUri = `${window.location.origin}/auth/google`;
  // const redirectUri =
  //   'storagerelay%3A%2F%2Fhttp%2Flocalhost%3A3000%3Fid%3Dauth372688';
  const clientId = encodeURIComponent(
    '77333393073-5rv0erfmv8ht3c6kb8qchgt2h956v2vn.apps.googleusercontent.com',
  );

  // https://accounts.google.com/o/oauth2/auth?redirect_uri=storagerelay%3A%2F%2Fhttp%2Flocalhost%3A3000%3Fid%3Dauth372688&response_type=permission%20id_token&scope=email%20profile%20openid&openid.realm=&include_granted_scopes=true&client_id=77333393073-5rv0erfmv8ht3c6kb8qchgt2h956v2vn.apps.googleusercontent.com&ss_domain=http%3A%2F%2Flocalhost%3A3000&prompt=&fetch_basic_profile=true&gsiwebsdk=2

  const authLink = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=permission%20id_token&scope=email%20profile%20openid&openid.realm&include_granted_scopes=true&nonce=12312`;
  console.log('authLink', authLink);

  const authWindowRef = openOAuthPopupWindow(authLink);
  authWindowRef.focus();
  await waitForWindowClose(authWindowRef);
  const code = authStorage.google.getCode();
  if (!code) {
    throw new Error('riot auth code is missing');
  }

  const data: api.OAuthData = { code, redirectUri };
  parseAuthReferralData(data);

  return data;
}
