import React, { useState, useRef } from 'react';

import cx from 'classnames';

import { api } from 'api';

import { utils } from 'utils';

import { useText } from '../Language';

import { ListProps } from './List';

import css from './List.module.css';

interface PaginationListProps {
  after: api.Maybe<boolean>;

  containerClassName?: string;
  loading?: boolean;

  onRequestMore?: () => Promise<unknown> | unknown;
}

export function PaginationList<T>({
  after,
  loading,
  className,
  containerClassName,
  onRequestMore = () => undefined,
  onSelectItem = () => undefined,
  ...props
}: PaginationListProps & ListProps<T>) {
  const controlsText = useText(state => state.controls);
  // isLoading avoids merging same chunk twice
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const intersectionTarget = useRef<HTMLDivElement | null>(null);

  const options: IntersectionObserverInit = {
    root: scrollRef.current,
    rootMargin: '0px',
  };

  utils.useIntersectionObserver(
    options,
    intersectionTarget,
    async () => {
      if (loading) {
        return;
      }

      setIsLoading(true);
      await onRequestMore();
      setIsLoading(false);
    },
    after,
    [loading],
  );

  const renderMembers = () => {
    if (!props.items) {
      return null;
    }

    if (props?.items.length === 0) {
      return 'Members not found';
    }

    return (
      <>
        {props.items.map((item, key) => (
          <div
            key={props.itemKey ? props.itemKey(item) : key}
            className={css.listItem}
            onClick={() => onSelectItem(item)}
          >
            {props.renderItem(item)}
          </div>
        ))}
      </>
    );
  };

  return (
    <div ref={scrollRef} className={cx(css.paginationContainer, containerClassName)}>
      <div className={className}>{renderMembers()}</div>
      {after ? (
        <div
          ref={intersectionTarget}
          onClick={() => {
            if (isLoading) {
              return;
            }

            onRequestMore();
          }}
        >
          {isLoading ? controlsText.loading : controlsText.actions.showMore}
        </div>
      ) : null}
    </div>
  );
}
