import * as analytics from './analytics';
import * as auth from './authStorage';
import * as date from './date';
import * as filterGame from './filterGame';
import * as links from './links';
import * as location from './location';
import * as main from './main';
import * as mock from './mock';

import * as wow from './wow';

/** @deprecated */
export const utils = {
  ...wow,
  ...date,
  ...main,
  ...auth,
  ...mock,
  ...links,
  ...analytics,
  ...location,
  ...filterGame,
};
